import React from "react"
import Layout from "../components/layout"
import { SRLWrapper } from "simple-react-lightbox"
import {graphql} from "gatsby"
import {HelmetDatoCms} from "gatsby-source-datocms"
import * as cn from "classnames"

export default class Article extends React.Component {
    state = {
    }

    componentDidMount() {
        this.setState({loading: false})
    }

    render() {
        const p = this.props, { } = p
        const article = p.data.article
        const page = p.data.page.edges.filter(({node: item}) => item.name === "Blog")

        const options = {
            thumbnails: {
                showThumbnails: false
            },
            settings: {
                overlayColor: "rgba(0,0,0,0.83)",
                autoplaySpeed: 1500,
                transitionSpeed: 900,
            },
            buttons: {
                backgroundColor: "transparent",
                iconColor: "#FEA21B",
            }
        }

        return (
            <Layout className="blog-post">
                <HelmetDatoCms seo={article.seoMetaTags} />
                {article.mainImage &&
                    <div className="main-image main-image--blog">
                        <img src={`${article.mainImage.fluid.src}&ar=9:2&h=660&fit=crop`}/>
                    </div>
                }
                <div className="content">
                    {page[0].node.header &&
                        <div className="page-title">
                            <h3>{page[0].node.header }</h3>
                        </div>
                    }
                    <div className="page-back">
                        <a href={`/${page[0].node.slug}`}><i className="icon-arrow-left"></i> Powrót</a>
                    </div>
                    <div className={cn("blog-post__content", article.textJustify && "centered", article.textCentered && "justify")}>
                        <h2>{article.title}</h2>
                        <pre className={cn(article.textJustify && "centered", article.textCentered && "justify")} dangerouslySetInnerHTML={{
                            __html: article.contentNode.childMarkdownRemark.html,
                        }}/>
                        <div className="iframe-container" dangerouslySetInnerHTML={{
                            __html: article.mediaNode.childMarkdownRemark.html,
                        }}>
                        </div>
                    </div>
                    {article.gallery.length > 0 &&
                        <div>
                            <div className="gallery">
                                <SRLWrapper options={options}>
                                    {article.gallery.map(({ fluid: image }) => (
                                        <a href={image.src} data-attribute="SRL">
                                            <img src={`${image.srcSet}&ar=1:1&fit=crop`} alt=""/>
                                        </a>
                                    ))}
                                </SRLWrapper>
                            </div>
                        </div>
                    }
                </div>
            </Layout>
        )
    }
}

export const query = graphql`
  query ArticlePageQuery($slug: String!) {
    article: datoCmsArticle(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      mainImage {
        fluid(imgixParams: { auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      title
      slug
      textJustify
      textCentered
      contentNode {
        childMarkdownRemark{
            html
        }
      }
      mediaNode {
        childMarkdownRemark{
            html
        }
      }
      gallery {
        fluid(imgixParams: { auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
    },
    page: allDatoCmsCustomPage {
          edges {
              node {
                seoMetaTags {
                    ...GatsbyDatoCmsSeoMetaTags
                }
                name
                slug
                header
              }
          }
      }
  }
`